import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"

export const client = new ApolloClient({
  uri: "https://graphql.datocms.com",
  fetch,
  request: operation => {
    operation.setContext({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer aaed7053226e5ba01724676dd78030`,
      },
    })
  },
})
